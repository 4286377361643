<template>
  <div>
    <a-spin :spinning="loading">
      <a-tabs v-model:activeKey="configKey" type="card" @change="onConfigKeyChange">
        <a-tab-pane v-for="item in configList" :key="item.key" :tab="item.name">

          <div v-if="item.key == 'servicePhone'">
            <a-form style="margin-top: 20px;" ref="form" name="form" :model="modelRef" scrollToFirstError :labelCol="{span: 4}" :wrapperCol="{span: 14}">
              <a-form-item label="客服电话" name="phone" :rules="[{required: true, message: '必填项不允许为空'}]">
                <a-input style="width: 400px" v-model:value="modelRef.phone" placeholder="请输入客服电话"></a-input>
              </a-form-item>
              <a-row>
                <a-col :offset="6">
                  <a-button v-permission="['operation_config_submit']" type="primary" html-type="submit" @click="onSaveTap(item.key)">提交</a-button>
                </a-col>
              </a-row>
            </a-form>
          </div>
          <div v-else-if="item.key == 'yonghanSetting'">
            <a-form style="margin-top: 20px;" ref="form" name="form" :model="modelRef" scrollToFirstError :labelCol="{span: 4}" :wrapperCol="{span: 14}">
              <a-form-item label="永汉咖啡小程序appid" name="appid" :rules="[{required: true, message: '必填项不允许为空'}]">
                <a-input style="width: 400px" v-model:value="modelRef.appid" placeholder="请输入小程序appid"></a-input>
              </a-form-item>
              <a-form-item label="永汉咖啡页面" name="path" :rules="[{required: false, message: '必填项不允许为空'}]">
                <a-input style="width: 400px" v-model:value="modelRef.path" placeholder="请输入页面路径"></a-input>
              </a-form-item>
              <a-row>
                <a-col :offset="6">
                  <a-button v-permission="['operation_config_submit']" type="primary" html-type="submit" @click="onSaveTap(item.key)">提交</a-button>
                </a-col>
              </a-row>
            </a-form>
          </div>
          <div v-else-if="item.key == 'appVersion'">
            <a-form style="margin-top: 20px;" ref="form" name="form" :model="modelRef" scrollToFirstError :labelCol="{span: 4}" :wrapperCol="{span: 14}">
              <a-form-item label="小程序版本号" name="version" :rules="[{required: true, message: '必填项不允许为空'}]">
                <a-input style="width: 400px" v-model:value="modelRef.version" placeholder="请输入小程序版本号"></a-input>
              </a-form-item>
              <a-row>
                <a-col :offset="6">
                  <a-button v-permission="['operation_config_submit']" type="primary" html-type="submit" @click="onSaveTap(item.key)">提交</a-button>
                </a-col>
              </a-row>
            </a-form>
          </div>
          <div v-else-if="item.key == 'certificateInfo'">
            <a-form style="margin-top: 20px;" ref="form" name="form" :model="modelRef" scrollToFirstError :labelCol="{span: 4}" :wrapperCol="{span: 14}">
<!--              extra="建议图片比例：1 : 1"-->
              <a-form-item label="图片">
                <a-upload
                    v-model:file-list="fileList"
                    list-type="picture-card"
                    action="/admin/ajaxUpload.do"
                    accept="image/*"
                    @change="handleChange"
                >
                  <div>
                    <Icon icon="PlusOutlined"></Icon>
                    <div style="margin-top: 8px">Upload</div>
                  </div>
                </a-upload>
              </a-form-item>
              <a-row>
                <a-col :offset="6">
                  <a-button v-permission="['operation_config_submit']" type="primary" html-type="submit" @click="onSaveTap(item.key)">提交</a-button>
                </a-col>
              </a-row>
            </a-form>
          </div>
          <div v-else-if="item.key == 'userAgreement' || item.key == 'privacyAgreement' || item.key == 'corporateCulture'">
            <a-form style="margin-top: 20px;" ref="form" name="form" :model="modelRef" scrollToFirstError :labelCol="{span: 4}" :wrapperCol="{span: 14}">
              <a-form-item label="内容" >
                <div style="position: relative;z-index: 99;">
                  <Editor v-model:value="modelRef.content" v-if="configKey == item.key"></Editor>
                </div>
				<div>展示位置：
					<span v-if="item.key === 'privacyAgreement'">小程序-我的-设置-关于我们</span>
					<span v-if="item.key == 'userAgreement'">小程序登录页</span>
					<span v-if="item.key == 'corporateCulture'">小程序-我的-设置-企业文化</span>
				</div>
              </a-form-item>
              <a-row>
                <a-col :offset="6">
                  <a-button v-permission="['operation_config_submit']" type="primary" html-type="submit" @click="onSaveTap(item.key)">提交</a-button>
                </a-col>
              </a-row>
            </a-form>
          </div>
          <div v-else-if="item.key == 'add'">
            <a-form style="margin-top: 20px;" ref="form" name="form" :model="modelRef" scrollToFirstError :labelCol="{span: 4}" :wrapperCol="{span: 14}">
              <a-form-item label="code" name="code" :rules="[{required: true, message: '必填项不允许为空'}]">
                <a-input style="width: 400px" v-model:value="modelRef.code" placeholder="请输入code"></a-input>
              </a-form-item>
              <a-form-item label="dataKey" name="dataKey" :rules="[{required: true, message: '必填项不允许为空'}]">
                <a-input style="width: 400px" v-model:value="modelRef.dataKey" placeholder="请输入dataKey"></a-input>
              </a-form-item>
              <a-form-item label="dataValue" name="dataValue" :rules="[{required: true, message: '必填项不允许为空'}]">
                <a-input style="width: 400px" v-model:value="modelRef.dataValue" placeholder="请输入dataValue"></a-input>
              </a-form-item>
              <a-form-item label="description" name="description" :rules="[{required: true, message: '必填项不允许为空'}]">
                <a-input style="width: 400px" v-model:value="modelRef.description" placeholder="请输入description"></a-input>
              </a-form-item>
              <a-row>
                <a-col :offset="6">
                  <a-button v-permission="['operation_config_submit']" type="primary" html-type="submit" @click="onSaveTap(item.key)">提交</a-button>
                </a-col>
              </a-row>
            </a-form>
          </div>
          <div v-else-if="item.key == 'del'">
            <a-form style="margin-top: 20px;" ref="form" name="form" :model="modelRef" scrollToFirstError :labelCol="{span: 4}" :wrapperCol="{span: 14}">
              <a-form-item label="id" name="id" :rules="[{required: true, message: '必填项不允许为空'}]">
                <a-input style="width: 400px" v-model:value="modelRef.id" placeholder="请输入id"></a-input>
              </a-form-item>
              <a-row>
                <a-col :offset="6">
                  <a-button v-permission="['operation_config_submit']" type="primary" html-type="submit" @click="onSaveTap(item.key)">提交</a-button>
                </a-col>
              </a-row>
            </a-form>
          </div>

        </a-tab-pane>
      </a-tabs>
    </a-spin>
  </div>
</template>

<script>
import { getDict, editConfig, addConfig, deleteConfig,getConfigList } from "@/service/modules/config";
import { Icon } from '@/components/icon/icon.js';
import Editor from '@/components/editor/editor';
export default {
  components: {
    Icon,
    Editor,
  },
  props: {
    isSee: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false,
      modelRef: {},
      configKey: 'servicePhone',
      fileList: [], // 上传图片列表
      configList: [{
        key: 'servicePhone',
        name: '客服电话',
      }, {
        key: 'yonghanSetting',
        name: '永汉咖啡',
      }, 
	  // {
   //      key: 'appVersion',
   //      name: '小程序版本号',
   //    }, 
	  {
        key: 'certificateInfo',
        name: '证照信息',
      }, {
        key: 'userAgreement',
        name: '用户协议',
      }, {
        key: 'privacyAgreement',
        name: '隐私协议',
      }, {
		key: 'corporateCulture',
		name: '企业文化'
	  }
      // , {
      //   key: 'add',
      //   name: '新增字典',
      // }, {
      //   key: 'del',
      //   name: '删除字典'
      // }
      ]
    }
  },
  created() {
    this.getConfigDetail();
    // this.getConfigList();
  },
  methods: {
    onConfigKeyChange(e) {
      this.configKey = e;
      this.modelRef = {};
      if(e != 'add' && e!= 'del') {
        this.getConfigDetail();
      }
    },
    // 获取配置列表
    async getConfigList() {
      const res = await getConfigList({});
      if(res.code == 200) {
        console.log("获取配置列表成功！");
        console.log(res.data);
      }
    },
    // 获取配置（添加配置流程：1.html新增模板 2.data的configList新增对象 3.methods的getConfigDetail新增赋值渲染 4.新增配置后请求到的数据需重新编辑一次）
    async getConfigDetail() {
      this.loading = true;
      try {
        const ret = await getDict({ key: this.configKey});
        if(ret.code == 200) {
          this.modelRef = {}
          const data = ret.data;
          data.dataValue = data.dataValue ? JSON.parse(data.dataValue) : {};
          // 配置后第一次获取数据需要再转换一次，编辑完一次后注释
          // if(this.configKey == 'privacyAgreement') {
          //   data.dataValue = JSON.parse(data.dataValue);
          // }
          // 判断 对于特殊字段进行处理
          if(this.configKey == 'certificateInfo') {
            if(data.dataValue.imageUrl) {
              this.fileList = data.dataValue.imageUrl.split(',').map(item => {
                return {
                  url: item
                }
              });
            }
          }else {
            this.modelRef = data.dataValue;
          }
          this.modelRef['id'] = data.id;
          this.modelRef['code'] = data.code;
        }
        this.loading = false;
      } catch (e) {
        this.loading = false;
      }
    },
    // 添加配置
    async addConfig() {
      this.modelRef.dataValue = JSON.stringify(this.modelRef.dataValue);
      const res = await addConfig(this.modelRef);
      if(res.code == 200) {
        this.$message.success('添加配置项成功！');
      }
    },
    // 编辑配置
    async editConfig() {
      if(this.configKey == 'certificateInfo') {
        // 是否有上传土图片
        if(this.modelRef.imageUrl) {
          const imgList = [];
          this.modelRef.imageUrl.forEach(item=>{
            imgList.push(item.url);
          });
          this.modelRef.imageUrl = [];
          this.modelRef.imageUrl = imgList.join(',');
        }else {
          this.modelRef.imageUrl = "";
        }
      }
      let dataValue = {};
      // 过滤掉id和code
      for(let key in this.modelRef) {
        if(key != 'id' && key != 'code') {
          dataValue[key] = this.modelRef[key];
        }
      }
      dataValue = JSON.stringify(dataValue);
      const res = await editConfig({
        id: this.modelRef.id,
        code: this.modelRef.code,
        dataKey: this.configKey,
        dataValue
      });
      if(res.code == 200) {
        this.$message.success('编辑配置项成功！');
      }
    },
    // 删除配置
    async deleteConfig() {
      const res = await deleteConfig({
        id:this.modelRef.id
      });
      if(res.code == 200) {
        this.$message.success('删除配置项成功！');
      }
    },
    onSaveTap(key) {
      if(key == 'add') {
        this.addConfig();
      }else if(key == 'del'){
        this.deleteConfig();
      }else {
        this.editConfig();
      }
    },
    // 图片列表变动
    handleChange(info) {
      if (info.file.status === 'done') {
        this.fileList = info.fileList;
        const $fileList = [...info.fileList];
        this.modelRef.imageUrl = $fileList.map(file => {
          if (file.response) {
            file.url = file.response.data.imgUrl;
          }
          return file;
        });
      }
    },
  }
}
</script>

<style scoped>
.ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>
